import React, { useEffect } from 'react';
import { Field, getFormValues } from 'redux-form';
import { FormTextInput } from '../../../components/form-inputs/form-text-input/form-text-input';
import { translate, translationKeys } from '../../../../logic/translations/translations.service';
import { STATE_CODE_MAX_LENGTH, trimValue } from '../../../../logic/formaters/formaters';
import { FormSelectInput } from '../../../components/form-inputs/form-select-input/form-select-input';
import { LocationFormFieldName } from '../../../../common/location/location-form.field-name';
import {
  formBlockHeaderLayout,
  formButtonLayout,
  formItemLayout,
} from '../../../components/form-container/form-container';
import { useDispatch, useSelector } from 'react-redux';
import { getInProgress } from '../../../../logic/store/process-tracker/process-tracker.selectors';
import { getFetchListTrackName, ListName, listsActionCreators } from '../../../../logic/store/lists/lists.action';
import { getListData } from '../../../../logic/store/lists/lists.selectors';
import { getDefaultSelectOptions } from '../../../components/form-inputs/select-option/select-option';
import { FormFieldPhone } from '../../../components/form-fields/form-field-phone';
import { Col, Form, Row, Typography } from 'antd';
import Title from 'antd/lib/typography/Title';
import parse from 'html-react-parser';

import { LocationE911MapFormField } from '../../components/location-e911-map-form-field';
import { FormSwitchInput } from '../../../components/form-inputs/form-switch-input/form-switch-input';
import { LabelWithToolTip } from '../../../components/form-inputs/label-with-tooltip/label-with-tooltip';
import { LocationForm, LocationFormProps } from '../../../../common/location/location.type';
import { getAuthenticatedUserRole } from '../../../../logic/store/authentication/authentication.selectors';
import { store } from '../../../../app/app.store';
import { isSuperAdmin } from '../../../authentication/models/role/role.utils';
import { EmergencyMode } from '../../../../api-models/api-models';
import FormValidationIcon from '../../../components/form-validation-icon/form-validation-icon';

export const LocationE911FormFields: React.FC<LocationFormProps> = ({
  onPressEnter,
  inProgress,
  disabledFields = [],
  isViewOnly,
  formName,
}) => {
  const dispatch = useDispatch();
  const role = getAuthenticatedUserRole(store.getState());
  const commonAttributes = (fieldName: LocationFormFieldName) => ({
    ...formItemLayout,
    disabled: isDisabled(fieldName) || inProgress,
    onPressEnter,
  });

  const formValues: LocationForm = useSelector(getFormValues(formName)) || {};

  useEffect(() => {
    dispatch(listsActionCreators.fetchList(ListName.company, undefined, 250));
  }, [dispatch]);

  const translateFieldLabel = (fieldName: LocationFormFieldName) =>
    translate(translationKeys.forms.fields.location[fieldName]);

  const getTextField = (fieldName: LocationFormFieldName, required?: boolean, maxLength?: number) => (
    <Field
      name={fieldName}
      component={FormTextInput}
      label={translateFieldLabel(fieldName)}
      required={required}
      maxLength={maxLength}
      onBlurFormater={trimValue}
      {...commonAttributes(fieldName)}
    />
  );

  const getContactTitle = (titleTranslationKey: string) => (
    <Form.Item {...formButtonLayout}>
      <Typography.Text strong>{translate(titleTranslationKey)}</Typography.Text>
    </Form.Item>
  );

  const getPhoneField = (
    phoneField: LocationFormFieldName,
    countryCodeField: LocationFormFieldName,
    countryCode: string | undefined,
    required?: boolean
  ) => (
    <FormFieldPhone
      name={phoneField}
      countryCodeFieldName={countryCodeField}
      countryCode={countryCode}
      label={translateFieldLabel(phoneField)}
      inProgress={inProgress}
      disabled={isDisabled(phoneField)}
      commonAttributes={commonAttributes(phoneField)}
      required={required}
    />
  );

  const isDisabled = (fieldName: LocationFormFieldName) => disabledFields.indexOf(fieldName) >= 0 || isViewOnly;

  const companies = useSelector(getListData(ListName.company)) || [];

  const companiesOptions = getDefaultSelectOptions(companies);

  const emergencyProviderOptions = [
    { label: 'E911', value: EmergencyMode.E911 },
    { label: 'Monitoring', value: EmergencyMode.Monitoring },
  ];

  const fetchCompaniesInProgress = useSelector(getInProgress(getFetchListTrackName(ListName.company)));
  const {
    first_emergency_contact_phone_country_code,
    second_emergency_contact_phone_country_code,
    third_emergency_contact_phone_country_code,
    monitoring_company_phone_number_country_code,
    monitoring_company_notification_sms_number_country_code,
    is_valid_in_bandwidth,
    ...otherFields
  }: LocationForm = useSelector(getFormValues(formName)) || {};

  return (
    <>
      <Form.Item {...formBlockHeaderLayout}>
        {getTextField(LocationFormFieldName.name, true)}
        <Field
          name={LocationFormFieldName.emergency_mode}
          component={FormSelectInput}
          label={translateFieldLabel(LocationFormFieldName.emergency_mode)}
          options={emergencyProviderOptions}
          {...commonAttributes(LocationFormFieldName.emergency_mode)}
          disabled={isDisabled(LocationFormFieldName.emergency_mode)}
          required
        />
        {formValues.emergency_mode === EmergencyMode.Monitoring && (
          <Row style={{ fontSize: 12, marginTop: -10, marginBottom: 20 }}>
            <Col {...formItemLayout.labelCol}></Col>
            <Col {...formItemLayout.labelCol}>
              {translate(translationKeys.forms.fields.location.monitoringServiceProviderLabel)}
              <br />
              <br />
              {parse(translate(translationKeys.forms.fields.location.monitoringServiceProviderAddress))}
            </Col>
          </Row>
        )}
        {formValues.emergency_mode === EmergencyMode.Monitoring && isSuperAdmin(role) && (
          <>
            {getPhoneField(
              LocationFormFieldName.monitoring_company_phone_number,
              LocationFormFieldName.monitoring_company_phone_number_country_code,
              monitoring_company_phone_number_country_code,
              true
            )}
            {getPhoneField(
              LocationFormFieldName.monitoring_company_notification_sms_number,
              LocationFormFieldName.monitoring_company_notification_sms_number_country_code,
              monitoring_company_notification_sms_number_country_code
            )}
            {getTextField(LocationFormFieldName.monitoring_company_notification_email)}
          </>
        )}
        {formValues.emergency_mode === EmergencyMode.E911 && (
          <Field
            name={LocationFormFieldName.geolocation_id}
            component={FormTextInput}
            label={translateFieldLabel(LocationFormFieldName.geolocation_id)}
            {...commonAttributes(LocationFormFieldName.geolocation_id)}
            disabled={true}
            toolTipMessage={translate(translationKeys.forms.fields.location.isValidInBandwidthTooltip)}
            addonAfter={<FormValidationIcon isValid={is_valid_in_bandwidth} />}
          />
        )}
        <Field
          name={LocationFormFieldName.company_id}
          component={FormSelectInput}
          label={translateFieldLabel(LocationFormFieldName.company_id)}
          options={companiesOptions}
          loading={fetchCompaniesInProgress}
          {...commonAttributes(LocationFormFieldName.company_id)}
          disabled={true}
        />

        {formValues.emergency_mode === EmergencyMode.E911 && (
          <>
            {getTextField(LocationFormFieldName.address, true)}
            {getTextField(LocationFormFieldName.city, true)}
            {getTextField(LocationFormFieldName.zip_code, true)}
            {getTextField(LocationFormFieldName.state, true, STATE_CODE_MAX_LENGTH)}
            {getTextField(LocationFormFieldName.country, true)}
          </>
        )}

        {formValues.emergency_mode === EmergencyMode.Monitoring && (
          <Field
            name={LocationFormFieldName.monitoring_company_voice_message}
            component={FormTextInput}
            label={translateFieldLabel(LocationFormFieldName.monitoring_company_voice_message)}
            onBlurFormater={trimValue}
            rows={5}
            {...commonAttributes(LocationFormFieldName.monitoring_company_voice_message)}
          />
        )}
      </Form.Item>

      {formValues.emergency_mode === EmergencyMode.E911 && (
        <>
          <Form.Item {...formBlockHeaderLayout}>
            <Title className={'text-centered'} level={4}>
              {translate(translationKeys.forms.fields.location.emergencyContactsHeader)}
            </Title>
            <div className={'text-justified'}>
              {parse(translate(translationKeys.forms.fields.location.emergencyContactsDescription))}
            </div>
          </Form.Item>
          <Form.Item {...formBlockHeaderLayout}>
            {getContactTitle(translationKeys.forms.fields.location.firstEmergencyContact)}
            {getTextField(LocationFormFieldName.first_emergency_contact_name)}
            {getPhoneField(
              LocationFormFieldName.first_emergency_contact_phone,
              LocationFormFieldName.first_emergency_contact_phone_country_code,
              first_emergency_contact_phone_country_code
            )}
            <Field
              name={LocationFormFieldName.first_emergency_contact_id}
              component={FormTextInput}
              label={translateFieldLabel(LocationFormFieldName.first_emergency_contact_id)}
              {...commonAttributes(LocationFormFieldName.first_emergency_contact_id)}
              disabled={true}
            />
          </Form.Item>
          <Form.Item {...formBlockHeaderLayout}>
            {getContactTitle(translationKeys.forms.fields.location.secondEmergencyContact)}
            {getTextField(LocationFormFieldName.second_emergency_contact_name)}
            {getPhoneField(
              LocationFormFieldName.second_emergency_contact_phone,
              LocationFormFieldName.second_emergency_contact_phone_country_code,
              second_emergency_contact_phone_country_code
            )}
            <Field
              name={LocationFormFieldName.second_emergency_contact_id}
              component={FormTextInput}
              label={translateFieldLabel(LocationFormFieldName.second_emergency_contact_id)}
              {...commonAttributes(LocationFormFieldName.first_emergency_contact_id)}
              disabled={true}
            />
          </Form.Item>
          <Form.Item {...formBlockHeaderLayout}>
            {getContactTitle(translationKeys.forms.fields.location.thirdEmergencyContact)}
            {getTextField(LocationFormFieldName.third_emergency_contact_name)}
            {getPhoneField(
              LocationFormFieldName.third_emergency_contact_phone,
              LocationFormFieldName.third_emergency_contact_phone_country_code,
              third_emergency_contact_phone_country_code
            )}
            <Field
              name={LocationFormFieldName.third_emergency_contact_id}
              component={FormTextInput}
              label={translateFieldLabel(LocationFormFieldName.third_emergency_contact_id)}
              {...commonAttributes(LocationFormFieldName.third_emergency_contact_id)}
              disabled={true}
            />
          </Form.Item>
        </>
      )}
      {formValues.emergency_mode === EmergencyMode.E911 && (
        <>
          <Form.Item {...formBlockHeaderLayout}>
            <Title className={'text-centered'} level={4}>
              {translate(translationKeys.forms.fields.location.mobileAlertAreaTitle)}
            </Title>
            <div className={'text-justified'}>
              {translate(translationKeys.forms.fields.location.mobileAlertAreaDescription)}
            </div>
            <br />
            <ul>
              <li>{translate(translationKeys.forms.fields.location.mobileAlertAreaTip1)}</li>
              <li>{translate(translationKeys.forms.fields.location.mobileAlertAreaTip2)}</li>
            </ul>
          </Form.Item>
          <Field
            {...formItemLayout}
            name={LocationFormFieldName.is_geolocation_enabled}
            label={
              <LabelWithToolTip
                label={translateFieldLabel(LocationFormFieldName.is_geolocation_enabled)}
                toolTipMessage={translate(translationKeys.forms.fields.location.gelocationTooltipMessage)}
              />
            }
            component={FormSwitchInput}
          />
          {otherFields.is_geolocation_enabled ? (
            <Field
              formValues={otherFields}
              name={LocationFormFieldName.geolocation}
              component={LocationE911MapFormField}
            />
          ) : null}
        </>
      )}
    </>
  );
};
